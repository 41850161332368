<!--
 * @Author       : JiangChao
 * @Date         : 2022-12-11 18:02:07
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-18 09:19:34
 * @Description  : 我的钱包
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <div style="font-size: 18px">我的錢包</div>
            <p style="margin-bottom: 10px; margin-top: 20px; text-align: left">
                租借前需綁定付款方式，歸還後通過綁定方式扣款
            </p>
            <div class="card">
                <van-cell title="請選擇付款方式" style="font-weight: 500">
                </van-cell>
                <van-radio-group v-model="radio" @change="onChange">
                    <van-cell-group :border="false">
                        <van-cell
                            title="支付押金"
                            clickable
                            @click="radio = '1'"
                        >
                            <template #right-icon>
                                <van-radio name="1">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell
                            title="免付押金"
                            clickable
                            @click="radio = '2'"
                        >
                            <template #right-icon>
                                <van-radio name="2">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell title="訂閱制" clickable @click="radio = '3'">
                            <template #right-icon>
                                <van-radio name="3">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
            </div>
            <div class="tip">
                <p>說明：</p>
                <p>支付押金：需支付384元押金（歸還時可自行退款），若八天未歸還則沒收押金。</p>
                <p>免付押金：無需支付押金，但若八天未歸還則由帳號扣除384元。</p>
                <p>
                    訂閱制：預先支付費用，可享訂閱期間不限時間與次數，無限制使用。
                </p>
                <div class="login-way">
                    <div class="sms-way" @click="confirm">確認</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";

export default {
    name: "",
    data() {
        return {
            radio: "",
            activeIcon: require("@/assets/imgs/png/icon_agree_selected_circle.png"),
            inactiveIcon: require("@/assets/imgs/png/icon_login_default_circle.png"),
        };
    },
    methods: {
        onChange(val) {
            console.log("val: ", val);
            this.radio = val;
        },
        confirm() {
            if (!this.radio) {
                handleErr({ app: this.$parent, msg: "請選擇付款方式！" });
                return;
            }
            switch (this.radio) {
                case "1":
                    this.$_href("PayByMultiChannel");
                    break;
                case "2":
                    this.$_href("PayByBankCard");
                    break;
                case "3":
                    this.$_href("PayBySubscription");
                    break;
            }
        },
        $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                this.$router.push(page);
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {},
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: #fee26c;
    text-align: center;
    padding: 20px;

    .card {
        border-radius: 10px;
        margin-top: 50px;
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
    .tip {
        position: fixed;
        bottom: 0;
        text-align: left;
        margin-bottom: 30px;
        p {
            font-size: 14px;
        }
    }
}
</style>
